import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from '../state/appState';

import { PageMeta } from '../components/pageMeta';

import { OneOffForm } from '../components/forms/oneOffForm';
import Seo from '../components/seo';

const ResidentEnquiry = () => {

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    toggleHeaderLight(true);
    setHideFooter(true);
  }, [toggleHeaderLight, setHideFooter]);

  return (
    <>
      <Seo title="One-off resident visa" />
      <div className="w-full px-gutter bg-brand text-white min-h-screen mt-8">
        <h1 className="font-display text-3xl md:text-4xl lg:text-5xl mb-12 pt-28 md:pt-32 lg:pt-48">
          Complete the below for a FREE Residency Pre-qualification
        </h1>
        <OneOffForm />
        <div className="w-full border-t border-white py-12 mt-24 lg:mt-60 text-white text-center text-sm flex flex-col md:flex-row justify-between gap-y-6">
          <a
            href="https://www.infrawork.co.nz/"
            target="_blank"
            rel="noreferrer"
            className="font-bold"
          >
            A part of the Infrawork Group
          </a>
          <div className="flex flex-col gap-6 md:flex-row">
            <span>© Visahub 2021. All rights reserved.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResidentEnquiry;
